<template>
  <div class="sxfxLineEchart">
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"

export default {
  props: ['id', 'ehartData', 'yName'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
    initEcharts() {
      let selected = {};
      let nameList = this.ehartData.dataY.map(e => {
        if(e.name != '全部'){
          selected[e.name] = false;
        }else {
          selected[e.name] = true;
        }
        return e.name})

    
      var option = {
        // color: ['#F99E9B', '#1EA0FE'],
        legend: {
          data: nameList,
          show: true,
          type: 'scroll',//plain scroll
          top: '4%',
          right: '5%',
          width: '70%',
          itemGap: 20, // 间隔距离
          selected,
        },

        grid: {
          show: true,
          left: '11%',
          right: '7%',
          top: '10%',
          bottom: '6%',
        },

        name: 'DATE',
        nameTextStyle: {
          //坐标轴名称的文字样式
          color: '#9AA1A9',
          fontSize: 14,
          padding: [0, 0, 0, 20],
          align: 'center',
        },
        xAxis: {
          type: 'category',
          // name: '月份(月)',
          data: this.ehartData.dataX,

          splitLine: {
            show: true,
          },
          axisLabel: {
            interval: 0,
          },
          boundaryGap: false,

          axisTick: {
            show: false,
            length: 10,
           
          },
          axisLabel: {
            //坐标文字
            color: '#5B6680',
          },

          axisLine: {
            show: true, //显示和隐藏轴线
            lineStyle: {
              //轴线样式设置
              color: 'rgba(215, 215, 215, 1)',
              width: 2,
              type: 'solid',
            },
          },
        },
        yAxis: {
          type: 'value',
          name: this.yName ? this.yName : '用量',
          splitNumber: '11',
          axisTick: {
            show: false,
            length: 10,
          },
          axisLabel: {
            formatter: '{value}',
          },

          axisLine: {
            show: true, //显示和隐藏轴线
            lineStyle: {
              //轴线样式设置
              color: 'rgba(215, 215, 215, 1)',
              width: 1,
              type: 'solid',
            },
          },
        },
        tooltip: {
          trigger: 'item',
        },
        series: this.ehartData.dataY,
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.sxfxLineEchart {
  width: 100%;
  .echarts {
    width: 100%;
    height: px(780);
  }
}
</style>